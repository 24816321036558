import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { zh_CN, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { ENVIROMENT } from './../environments/environment';
import { GLOBAL_ENVIROMENT } from './../environments/interface';

import { httpInterceptorProviders } from './http-interceptors';

import { provideStore } from '@ngxs/store';
import {
  ChatCategoryState,
  ChatRecordState,
  PortraitState,
  UserState,
  ChatModeState,
  TokenState,
  EnterpriseState,
} from './ngxs/index.state';

registerLocaleData(zh);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideNzI18n(zh_CN),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors(httpInterceptorProviders)),
    provideStore([
      ChatCategoryState,
      ChatRecordState,
      PortraitState,
      UserState,
      ChatModeState,
      TokenState,
      EnterpriseState,
    ]),
    NzIconModule,
    {
      provide: GLOBAL_ENVIROMENT,
      useValue: ENVIROMENT,
    },
  ],
};
