import { inject } from '@angular/core';
import { HttpHandlerFn, HttpRequest, HttpHeaders } from '@angular/common/http';

import { StorageService } from '../services/storage/storage.service';

export function AuthorizationInterceptor(
  req: HttpRequest<AsAny.AsAny>,
  next: HttpHandlerFn
) {
  const token = inject(StorageService).getLocalStroage<string>(
    inject(StorageService).TOKEN
  );
  let headers!: HttpHeaders;

  if (token) {
    headers = req.headers.set('Authorization', `Bearer ${token}`);
  }

  const authReq = req.clone({
    headers,
  });
  return next(authReq);
}
