// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { GlobalEnviroment } from './interface';

export const ENVIROMENT: GlobalEnviroment = {
  production: false,

  baseUrl: 'https://dev-user-api.chat1.vosbyte.com',

  token: 'DEV_LEPITS_TOKEN',
  user: 'DEV_LEPITS_USER',

  captchaAppId: '194346015',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
