import {
  HttpHandler,
  HttpErrorResponse,
  HttpRequest,
  HttpHandlerFn,
} from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

enum StateCode {
  success = 200,
  notFound = 404,
  error = 500,
  serverError = 503,
}

export function ErrorInterceptor(req: HttpRequest<any>, next: HttpHandlerFn) {
  return next(req).pipe(catchError(handleError));
}

function handleError(e: HttpErrorResponse) {
  const error: Global.IResponse<null> = {
    code: e.status,
    message: '',
    data: null,
  };

  switch (e.status) {
    case StateCode.notFound:
      error.message = '无定义';
      break;
    case StateCode.error:
      error.message = '服务器连接失败,请稍后重试';
      break;
    case StateCode.serverError:
      error.message = '服务器连接失败,请稍后重试';
      break;
    default:
      error.message = '服务器连接失败,请稍后重试';
      break;
  }

  return throwError(error);
}
